/* Global Styles */
.home {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between; /* Add space between text and image */

  padding: 0 5%; /* Add padding on the sides */
}

.intro_sec {
  display: flex-column;
  justify-content: end;
  padding-left: 200px;
  flex: 1;
  max-width: 100%;
}

.mb-1x {
  margin-bottom: 1rem;
}
.boxen {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;

  order: 2; /* Ensure image is second */
  height: auto;
  width: 800px;
  margin-right: 10rem;

  display: flex; /* Use flex to align the image if needed */
  align-items: start; /* This will vertically align the image in the container */
}
.profilbild {
  display: flexbox;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.intro_btn-action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.ac_btn {
  padding: 4px 15px;
  color: var(--secondary-color);
  position: relative;
  border: var(--secondary-color) 2px solid;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  cursor: pointer;
  border-radius: 0;
  margin-right: 20px;
}

.ac_btn a {
  text-decoration: none;
}

.ac_btn:hover {
  box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
  background-color: #000;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
  z-index: -4;
}

.ac_btn .two {
  background-color: var(--primary-color);
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
}

.ac_btn .three {
  background-color: var(--secondary-color);
  z-index: -2;
  transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
}

#button_p {
  background: var(--secondary-color);
  color: var(--primary-color);
}
#button_p:hover {
  background: var(--primary-color);
  color: var(--secondary-color);
}
#button_h:hover {
  background: var(--secondary-color);
  color: var(--secondary-color);
}
#button_c {
  background: var(--secondary-color);
  color: var(--primary-color);
  margin: 10px;
}
#button_c:hover {
  background: var(--third-color);
  color: var(--secondary-color);
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex; /* Enables flexbox */
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
}

.modal-content {
  background-color: var(--primary-color); /* Green background */
  color: var(--secondary-color); /* Red text color */
  text-align: center;
  padding: 40px;
  border: 1px solid #888;
  width: 50%; /* Adjust width as necessary */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: center;
}

.close {
  color: red; /* Red close button for consistency */
  float: right;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: darkred; /* Darker red on hover/focus */
  text-decoration: none;
}
@media (max-width: 1100px) {
  .home {
    padding: 0 5%;
    grid-template-columns: 1fr; /* Change to single column layout */
  }
  .boxen {
    order: 1;
    width: 100%; /* Use full width of the viewport */
    height: auto; /* Auto height */
  }
  .inner-boxen {
    width: 100%; /* Fit to the width of .boxen */
  }
  .intro_sec {
    padding: 0; /* Remove padding for smaller screens */
    order: 2;
    flex: 1;
    max-width: 100%; /* Full width for smaller screens */
  }
  .mb-1x {
    padding-right: 0;
  }
  .intro_btn-action {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .boxen {
    margin-right: 0; /* Remove margin for smaller screens */
  }
  .ac_btn {
    justify-content: center;
    font-size: small;
    padding: 5px 10px;
  }
  .modal-content {
    width: 90%;
    padding: 1em;
  }

  .close {
    font-size: 1.5em;
    top: 1em;
    right: 1em;
  }
}
