.sec_sp {
  margin-bottom: calc(3rem + 5.128vw);
}
.table td,
.table th {
  color: var(--text-color);
  background-color: var(--background-color); /* Add this line */
}

.t_border {
  border-color: var(--text-color) !important;
}

.sec_sp {
  margin-bottom: calc(3rem + 5.128vw);
}

.skill-section {
  margin-bottom: 30px; /* Add spacing between each skill */
}

.skill-title {
  font-size: 22px;
  font-weight: 700;
  color: #ff1493; /* Use a strong color to make the title pop */
  font-family: "Raleway", sans-serif;
}

.skill-description {
  font-size: 16px;
  color: #ccc; /* Lighter text for description */
  font-family: "Raleway", sans-serif;
  margin-bottom: 20px;
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}

@keyframes animate-positive {
  0% {
    width: 0%;
  }
}

.section-title {
  font-size: 45px;
}

.service__title {
  padding: 8px 0;
  border-bottom: solid 2px var(--secondary-color);
}

.service-section .service-category-title {
  padding-bottom: 4px;
}

/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@media (max-width: 748px) {
  .custom-icon-class5 {
    width: 5px;
  }
}

@media (max-width: 1100px) {
  .service-section .service-category-title {
    padding-bottom: 4px;
  }
  .icon-container {
    display: grid;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* This will organize your icons into three columns */
    gap: 20px; /* This adds a 20px gap between your icons */
    padding-left: 10px;
  }
  /* Animations */
  /* Side-to-Side Animation */
  @keyframes sideToSideAnimation {
    0%,
    100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(20px);
    }
  }

  .custom-icon-class2,
  .icon-side-to-side {
    animation: sideToSideAnimation 2s ease-in-out infinite;
  }

  /* Rotation Animation */
  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .icon-rotate {
    animation: rotateAnimation 4s linear infinite;
  }
}

.icon {
  font-size: 4em; /* Makes the icons larger */
  margin: 0 10px; /* Adds a 10px margin to the left and right of each icon */
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the icons */
  transition: transform 0.2s; /* Smoothens the transformation effects */
}

/* Icon-specific colors */
.icon-html {
  color: #e34f26;
}
.icon-css {
  color: #1572b6;
}
.icon-js {
  color: #f7df1e;
}
.icon-react {
  color: #61dbfb;
}
.icon-node {
  color: #68a063;
}
.icon-git {
  color: #f1502f;
}
.icon-github {
  color: #ffffff;
}
.icon-npm {
  color: #cb3837;
}
.icon-yarn {
  color: #2c8ebb;
}
.icon-trello {
  color: #0079bf;
}
.icon-figma {
  color: #f24e1e;
}

.custom-icon-class {
  width: 5rem;
  height: auto;
}
.custom-icon-class2 {
  width: 10rem;

  height: auto;
}
.custom-icon-class3 {
  background-color: white;
  border-radius: 50%;
  width: 5rem;
  height: auto;
}
.custom-icon-class4 {
  background-color: white;
  border-radius: 10px;
  width: 5rem;
  height: auto;
}
.custom-icon-class5 {
  width: 6rem;
  background-color: white;
  border-radius: 7%;
  padding-bottom: 4px;
  height: auto;
}
.custom-icon-class,
.custom-icon-class2,
.custom-icon-class3,
.custom-icon-class4,
.custom-icon-class5,
.icon-side-to-side {
  animation: sideToSideAnimation 3s ease-in-out infinite;
}
@keyframes sideToSideAnimation {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}
